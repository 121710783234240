


























import { Component, Prop, Vue } from "vue-property-decorator";
import { ListFilter } from "@/models/entities/list-filter.interface";

@Component
export default class CosmopolFiltersGroup extends Vue {
  @Prop({ required: false, default: [], type: Array })
  private filters: ListFilter[];
  @Prop({ required: false, default: null, type: String })
  private selected: string;
  @Prop({ required: false, default: "", type: String })
  private title: string;
}
